import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import data from '../assets/data/pageData.json'
import Layout from '../components/layout'
import { FormStepSuccessFormat } from '../components/FormStepSuccess'
import SEO from '../components/seo'

const PrivacyPage = () => {
  return (
    <Layout
      title = {data?.privacyPage?.sectionTitle}
    >
      <SEO
        description={'The SDA is committed to protecting your privacy and providing you with information and services relevant to you.'}
        lang={'en'}
        title={'SDA Union | Privacy'}
        meta={[{
          name: 'SDA Union | Privacy',
          content: 'The SDA is committed to protecting your privacy and providing you with information and services relevant to you.'
        }]}
      />
      <FormStepSuccessFormat
        heading={data?.privacyPage?.sectionHeading}
        title={undefined}
        fcpContent={data?.privacyPage?.rows}
        isFormStepVisible={false}
      />
    </Layout>
  )
}
export default PrivacyPage
